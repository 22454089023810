<template>
  <div>
    <div :class="$style.processTitle" style="margin-bottom: 10px">
      Realizar Recarga
      <a-tooltip
        placement="top"
        title="Essa ação pode ser irreversivel, caso queira remover licensa, inclua Reembolso para o mesmo email"
      >
        <a-button size="small" type="link" style="color: #fff">
          <template #icon><QuestionCircleOutlined /></template>
        </a-button>
      </a-tooltip>
    </div>
    <a-row>
      <a-col :span="24">
        <a-form
          ref="rechargeForm"
          layout="vertical"
          :model="formState"
          scrollToFirstError
          @finish="handleFinish"
          @finishFailed="handleFinishFailed"
        >
          <a-form-item
            label="Email"
            name="email"
            :rules="{
              required: true,
              type: 'email',
              message: 'Digite o email email recarga',
              trigger: 'change',
            }"
          >
            <a-input
              :class="$style.rounded"
              v-model:value="formState.email"
              type="email"
              placeholder="Email"
              size="large"
            >
              <template #prefix
                ><LockOutlined style="color: rgba(0, 0, 0, 0.25)"
              /></template>
            </a-input>
          </a-form-item>
          <a-form-item
            label="Produto"
            name="product"
            :rules="{
              required: true,
              message: 'Selecione um produto!',
              trigger: 'change',
            }"
          >
            <a-select
              :class="$style.rounded"
              v-model:value="formState.product"
              type="product"
              placeholder="Produto"
              :options="normalizedProducts"
              size="large"
            >
              <template #prefix
                ><LockOutlined style="color: rgba(0, 0, 0, 0.25)"
              /></template>
            </a-select>
          </a-form-item>
          <a-form-item
            label="Operação"
            name="type"
            :rules="{
              required: true,
              message: 'Selecione o tipo de operação!',
              trigger: 'change',
            }"
          >
            <a-select
              :class="$style.rounded"
              v-model:value="formState.type"
              placeholder="Tipo de Operação"
              size="large"
            >
              <a-select-option value="PURCHASE_APPROVED">Pago</a-select-option>
              <a-select-option value="PURCHASE_REFUNDED"
                >Reembolso</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item
            label="Motivo"
            name="description"
            :rules="{
              required: true,
              message: 'Digite o motivo pela recarga',
              trigger: 'change',
            }"
          >
            <a-input
              :class="$style.rounded"
              v-model:value="formState.description"
              type="text"
              placeholder="Motivo"
              size="large"
            >
              <template #prefix
                ><LockOutlined style="color: rgba(0, 0, 0, 0.25)"
              /></template>
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-button
              html-type="submit"
              type="primary"
              :class="$style.saveButton"
              :loading="isLoading"
              :disabled="
                formState.password === '' ||
                formState.password !== formState.passwordConfirmation
              "
            >
              Inserir
            </a-button>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { defineComponent, reactive, ref } from "vue";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { isDesktop } from "@/utils/isAgent";
import { message } from "ant-design-vue";

export default defineComponent({
  setup() {
    const formRef = ref();

    const formState = reactive({
      email: "",
      product: "",
      type: "PURCHASE_APPROVED",
      description: "",
    });

    const value = ref("");
    return { value, isDesktop, formState, formRef };
  },
  components: { QuestionCircleOutlined },
  methods: {
    ...mapActions("recharge", ["createRecharge"]),
    async handleFinish() {
      try {
        const data = {
          email: this.formState.email,
          product: this.formState.product,
          type: this.formState.type,
          description: this.formState.description,
        };
        await this.createRecharge(data);

        message.success("Recarga criada com sucesso!");
      } catch (e) {
        message.error("Não foi possivel criar recarga.");
      }
    },
  },
  computed: {
    ...mapState("application", ["isLoading"]),
    ...mapState("system", ["amountSubscriptionAvailable", "products"]),
    ...mapGetters("system", ["isSystemEnable"]),
    normalizedProducts() {
      return this.products?.map((e) => ({
        label: e.description,
        value: e.id,
      }));
    },
  },
});
</script>

<style module src="./style.scss" lang="scss"></style>
