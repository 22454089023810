<template>
  <div>
    <a-row>
      <a-col :span="isDesktop ? 18 : 24" style="padding-right: 10px">
        <a-col :span="24">
          <LineChart v-bind="lineChartProps" />
        </a-col>
        <a-col :span="24">
          <!-- <HomeFilter :adminMode="true" :hideLicenses="true" /> -->
          <div :class="$style.tableTravel">
            <a-table
              :row-selection="null"
              :class="$style.tableColumm"
              size="middle"
              :loading="isLoading"
              :row-key="(record) => record.id"
              tableLayout="auto"
              :pagination="tablePagination"
              :columns="columns"
              :data-source="recharges"
              @change="onChange"
            >
              <template #customTitle>
                <a-space>
                  E-mail do usuário
                  <a-tooltip placement="top" title="Email de cadastro do usuario">
                    <a-button shape="circle" size="small">
                      <template #icon><QuestionOutlined /></template>
                    </a-button>
                  </a-tooltip>
                </a-space>
              </template>
              <template #renderEmail="{ record }">
                {{ record.user.email }}
              </template>
              <template #renderOrderRef="{ record }">
                {{ record.order_ref }}
              </template>
              <template #statusRender="{ record }">
                <a-tag color="success" v-if="record.order_status == 'paid'">
                  <template #icon>
                    <check-circle-outlined />
                  </template>
                  Pago
                </a-tag>
                <a-tag color="error" v-if="record.order_status == 'refunded'">
                  <template #icon>
                    <close-circle-outlined />
                  </template>
                  Reembolso
                </a-tag>
              </template>
            </a-table>
          </div>
        </a-col></a-col
      >
      <a-col :span="6" style="padding-left: 10px" v-if="isDesktop">
        <a-col :span="24"><Recharge /></a-col>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import Recharge from "@/components/Recharge/Recharge";
import { isDesktop } from "@/utils/isAgent";
import { mapActions, mapGetters, mapState } from "vuex";
import { LineChart, useLineChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import { QuestionOutlined } from "@ant-design/icons-vue";

Chart.register(...registerables);

const columns = isDesktop
  ? [
      {
        dataIndex: "user",
        sortDirections: ["descend", "ascend"],
        slots: {
          title: "customTitle",
          customRender: "renderEmail",
        },
      },
      {
        title: "Manual",
        dataIndex: "is_manually",
        filterMultiple: false,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Quantidade de Vistos",
        dataIndex: "amount",
        filterMultiple: false,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "ID da Transação/Motivo",
        slots: {
          customRender: "renderOrderRef",
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        sorter: true,
        filters: [
          {
            text: "Pago",
            value: "paid",
          },
          {
            text: "Reembolso",
            value: "refunded",
          },
        ],
        filterMultiple: false,
        // onFilter: (value, record) => record.address.indexOf(value) === 0,
        // sorter: (a, b) => a.address.length - b.address.length,
        sortDirections: ["descend"],
        slots: {
          customRender: "statusRender",
        },
      },
      {
        title: "Data de criação",
        dataIndex: "created_at",
        filterMultiple: false,
        sortDirections: ["descend", "ascend"],
      },
    ]
  : [
      {
        dataIndex: "user",
        sortDirections: ["descend", "ascend"],
        slots: {
          title: "customTitle",
          customRender: "renderEmail",
        },
      },
      {
        title: "Data de criação",
        dataIndex: "created_at",
        filterMultiple: false,
        sortDirections: ["descend", "ascend"],
      },
    ];

export default defineComponent({
  components: {
    Recharge,
    QuestionOutlined,
    LineChart,
  },
  data() {
    return {
      isDesktop,
      columns,
      graphData: null,
      lineChartProps: null,
      lineChartRef: null,
      data: [30, 40, 60, 70, 5],
      labels: ["Paris", "Nîmes", "Toulon", "Perpignan", "Autre"],
      legendTop: "Data",
      imgData: null,
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "xy",
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters("user", ["isAdmin"]),
    ...mapState("recharge", ["recharges", "chart", "pagination"]),
    tablePagination() {
      return {
        total: this.pagination.totalItems,
        current: this.pagination.currentPage,
        pageSize: this.pagination.itemsPerPage,
      };
    },
  },
  methods: {
    ...mapActions("recharge", ["getRecharges", "populateRechargeChart"]),
    async onChange(pagination, filters, sorter) {
      const hasOrder = !sorter?.order || sorter?.order == "ascend" ? true : false;
      const filtersKey = Object.keys(filters)[0];
      const data = {
        page: pagination.current,
        where:
          filtersKey && filters
            ? {
                [filtersKey]: filters[filtersKey][0],
              }
            : {},
        order: {
          column: sorter.field,
          order: hasOrder ? "ASC" : "DESC",
        },
      };
      await this.getRecharges(data);
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
    zoom() {
      this.lineChartRef.value.chartInstance.zoom(1.01);
    },
    refreshData() {
      const { lineChartProps, lineChartRef } = useLineChart({
        chartData: this.chart,
        option: this.option,
      });

      this.lineChartProps = lineChartProps;

      this.lineChartRef = lineChartRef;
    },
  },
  mounted() {
    this.getRecharges();
    this.populateRechargeChart();
    this.refreshData();
  },

  watch: {
    chart() {
      this.refreshData();
    },
  },
});
</script>

<style module src="./style.scss" lang="scss"></style>
