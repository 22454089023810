<template>
  <div>
    <a-tabs v-model:activeKey="activeKey" type="card">
      <a-tab-pane key="application" tab="Aplicações">
        <a-row>
          <a-col :span="isDesktop ? 18 : 24" style="padding-right: 10px">
            <a-col :span="24">
              <HomeFilter :adminMode="true" :hideLicenses="true" />
            </a-col>
            <a-col :span="24">
              <MyProcesses
                allProcessTitle="Últimos processos"
                :adminMode="true"
              /> </a-col
          ></a-col>
          <a-col :span="6" style="padding-left: 10px" v-if="isDesktop">
            <a-col :span="24"><RecentUsers /> </a-col>
          </a-col>
        </a-row>
      </a-tab-pane>
      <a-tab-pane key="recharge" tab="Recarga">
        <Recharge />
      </a-tab-pane>
      <a-tab-pane key="users" tab="Usuários" v-if="isAdmin">
        <a-row>
          <a-col :span="isDesktop ? 18 : 24" style="padding-right: 10px">
            <a-col :span="24">
              <!-- <HomeFilter :adminMode="true" :hideLicenses="true" /> -->
              TODO
            </a-col>
            <a-col :span="24"> TODO</a-col></a-col
          >
          <a-col :span="6" style="padding-left: 10px" v-if="isDesktop">
            <a-col :span="24">TODO</a-col>
          </a-col>
        </a-row>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import RecentUsers from "@/components/RecentUsers/RecentUsers";
import MyProcesses from "@/components/MyProcesses/MyProcesses";
import HomeFilter from "@/components/HomeFilter/HomeFilter";
import Recharge from "@/containers/Recharge/Recharge";
import { isDesktop } from "@/utils/isAgent";
import { mapActions, mapGetters } from "vuex";
export default defineComponent({
  components: {
    RecentUsers,
    MyProcesses,
    Recharge,
    HomeFilter,
  },
  data() {
    return { isDesktop, activeKey: "application" };
  },
  computed: {
    ...mapGetters("user", ["isAdmin"]),
  },
  methods: {
    ...mapActions("system", ["getAllSuggestions", "getAllProducts"]),
  },
  mounted() {
    this.getAllProducts();
  },
});
</script>

<style module src="./style.scss" lang="scss"></style>
