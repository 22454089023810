<template>
  <div :class="$style.content">
    <div :class="$style.processTitle">
      Novos usuários
      <a-tooltip placement="top" :title="SAVED_PROCESS">
        <a-button size="small" type="link" style="color: #fff">
          <template #icon><QuestionCircleOutlined /></template>
        </a-button>
      </a-tooltip>
    </div>
    <a-input-search
      v-model:value="formState.value"
      placeholder="Buscar por..."
      :loading="isSavedLoading"
      style="width: 100%"
      :class="$style.filter"
      @search="handleSearch"
    />
    <a-list
      item-layout="horizontal"
      :data-source="users"
      :loading="isSavedLoading"
    >
      <template #renderItem="{ item }">
        <a-list-item>
          <a-list-item-meta :description="'Criado em: ' + item.createdAt">
            <template #title>
              <a @click="fetchApplicationsByUser(item.id)">{{ item.name }}</a>
            </template>
          </a-list-item-meta>
        </a-list-item>
      </template>
    </a-list>
    <div :class="$style.pagination">
      <a-pagination
        v-if="hasItem"
        @change="handleChange"
        size="small"
        :total="pagination.totalItems"
        :page-size="pagination.itemsPerPage"
        :current="pagination.currentPage"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";
import { reactive } from "vue";
import { SAVED_PROCESS } from "@/utils/tooltipConstants";
export default {
  name: "RecentProcess",
  components: { QuestionCircleOutlined },
  data() {
    const formState = reactive({
      value: "",
    });

    return {
      formState,
      SAVED_PROCESS,
    };
  },
  methods: {
    ...mapActions("user", ["getAllUsers"]),
    ...mapActions("application", ["getManagerApplications"]),
    async handleSearch(val) {
      const data = {
        where: {
          name: val,
        },
        order: {
          column: "createdAt",
          order: "DESC",
        },
      };
      await this.getAllUsers(data);
    },

    async handleChange(page) {
      const data = {
        page,
        where: {
          name: this.formState.value,
        },
        order: {
          column: "createdAt",
          order: "DESC",
        },
      };
      await this.getAllUsers(data);
    },
    async fetchApplicationsByUser(userId) {
      const data = {
        page: 1,
        where: { userId },
      };
      await this.getManagerApplications(data, userId);
    },
  },
  computed: {
    ...mapState("user", ["isLoading", "users", "pagination"]),
    hasItem() {
      return this.users.length > 0;
    },
  },
  async mounted() {
    try {
      await this.getAllUsers({
        order: {
          column: "createdAt",
          order: "DESC",
        },
      });
    } catch (e) {
      console.log(e);
    }
  },
};
</script>

<style module src="./style.scss" lang="scss"></style>
