<template>
  <Management />
</template>
<script>
import { defineComponent } from "vue";
import Management from "@/containers/Management/Management";
export default defineComponent({
  components: {
    Management,
  },
  setup() {
    return {};
  },
});
</script>
